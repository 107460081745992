import React from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    container: {
      width: 296,
      paddingLeft: 16,
      paddingRight: 16,
    },
    list: {
      color: "#83898e",
    },
    nested: {
      paddingLeft: theme.spacing(4),
      marginTop: -12,
    },
  }),
  { name: "N0AppDrawer" },
);

function AppDrawer({ onClose, open, ...other }) {
  const classes = useStyles();

  return (
    <Drawer anchor="right" onClose={onClose} open={open} {...other}>
      <div className={classes.container}>
        <List className={classes.list} component="div" dense>
          <ListItem
            disableGutters
            component={Link}
            href="https://www.usps.com/ship/"
            target="_blank"
          >
            <ListItemText
              primary="Send"
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem
            disableGutters
            component={Link}
            href="https://www.usps.com/manage/"
            target="_blank"
          >
            <ListItemText
              primary="Receive"
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem
            disableGutters
            component={Link}
            href="https://store.usps.com/store/home"
            target="_blank"
          >
            <ListItemText
              primary="Shop"
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
          <ListItem
            disableGutters
            component={Link}
            href="https://faq.usps.com/s/"
            target="_blank"
          >
            <ListItemText
              primary="Help"
              primaryTypographyProps={{ variant: "subtitle2" }}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
export default AppDrawer;
