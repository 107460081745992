import React from "react";

const styles = {
  st0: {
    fill: "#FFFFFF",
  },
  st1: {
    fill: "#333366",
  },
  st2: {
    fillRule: "evenodd",
    clipRule: "evenodd",
    fill: "#333366",
  },
  background: {
    enableBackground: "new 0 0 390 47",
  },
};

function USPSLogo() {
  return (
    <svg
      width="144px"
      height="29px"
      viewBox="0 0 144 29"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>bit / logo / USPS</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="bit-/-logo-/-USPS"
          transform="translate(-8.000000, -11.000000)"
          fillRule="nonzero"
        >
          <g transform="translate(8.000000, 11.000000)">
            <polygon
              id="Path"
              fill="#FFFFFF"
              points="39.78 28.32 45.78 0 6 0 0 28.32"
            ></polygon>
            <path
              d="M29.58,5.94 C34.2,7.02 34.32,8.16 34.32,8.16 C37.32,8.16 38.1,8.16 38.64,8.7 C40.38,10.32 37.44,14.28 37.44,14.28 C37.08,14.52 3.96,27.48 3.96,27.48 L38.82,27.48 L44.4,1.14 L6.96,1.14 C6.96,1.14 29.04,5.82 29.58,5.94"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M33,9.78 C32.52,10.02 31.08,10.26 30.6,10.32 C30.12,10.38 29.88,10.38 29.88,10.5 C29.88,10.68 30.54,10.62 30.78,10.62 C31.86,10.62 36.12,10.32 37.14,10.5 C37.92,10.62 37.02,12.18 36.78,13.14 C36.72,13.5 36.96,13.32 37.08,13.14 C37.38,12.66 38.22,11.1 38.28,10.5 C38.4,9.3 37.56,9.12 36.12,9.12 L33.9,9.12 C33.72,9.12 33.66,9.18 33.6,9.3 C33.42,9.48 33.12,9.66 33,9.78"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M19.2,18.66 L1.38,27.48 L5.7,7.26 L5.7,7.26 L23.7,7.26 C24,7.26 33.36,7.26 33.54,9.12 L17.34,9.12 L19.86,16.8 C22.74,15.42 29.82,12.54 34.08,12.36 C35.88,12.3 36.6,12.6 36.24,12.84 C36,12.96 34.26,13.2 32.88,13.56 C30.48,14.16 30.36,14.16 27.84,15 C25.02,15.96 22.68,17.04 19.2,18.66"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M56.52,28.08 C49.56,28.08 45.42,25.32 46.8,19.14 C49.08,8.46 50.58,1.32 50.58,1.32 L56.4,1.32 C56.4,1.32 52.86,17.76 52.98,17.4 C52.08,21.48 52.98,23.94 57.42,23.94 C61.68,23.94 63.84,21.48 64.74,17.4 C65.28,15.06 68.16,1.32 68.16,1.32 L73.98,1.32 C70.08,19.14 73.98,1.32 70.14,19.14 C68.7,25.26 63.42,28.08 56.52,28.08"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M105.06,17.58 C104.16,17.58 102.24,17.46 101.28,17.28 L102,13.5 C102.72,13.56 103.68,13.68 104.58,13.68 C108.54,13.68 109.98,10.98 110.28,9.36 C110.88,6.48 109.44,5.46 107.34,5.46 L102.6,5.46 L97.92,27.6 L92.1,27.6 L97.68,1.32 L110.28,1.32 C115.98,1.32 116.64,6.3 115.92,9.6 C114.84,14.52 110.22,17.58 105.06,17.58"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M92.76,5.52 L86.58,5.52 C83.4,5.52 82.02,6.18 81.72,7.44 C80.82,11.64 92.16,14.94 91.08,21.42 C90.48,25.02 87.36,27.6 80.1,27.6 L71.22,27.6 L72.12,23.4 L79.2,23.4 C81.42,23.4 84.12,23.52 84.6,21.3 C85.44,17.52 74.28,13.8 75.12,7.5 C75.66,3.72 79.02,1.32 85.8,1.32 L93.6,1.32 L92.76,5.52 Z"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M136.02,5.52 L129.84,5.52 C126.66,5.52 125.28,6.18 124.98,7.44 C124.08,11.64 135.42,14.94 134.34,21.42 C133.74,25.02 130.62,27.6 123.36,27.6 L114.48,27.6 L115.38,23.4 L122.52,23.4 C124.74,23.4 127.44,23.52 127.92,21.3 C128.76,17.52 117.6,13.8 118.44,7.5 C118.98,3.72 122.34,1.32 129.12,1.32 L136.92,1.32 L136.02,5.52 Z"
              id="Path"
              fill="#333366"
            ></path>
            <path
              d="M138,4.5 C138,2.94 139.2,1.74 140.7,1.74 C142.2,1.74 143.4,2.94 143.4,4.5 C143.4,6.12 142.2,7.32 140.7,7.32 C139.14,7.32 138,6.12 138,4.5 M137.4,4.5 C137.4,6.36 138.9,7.8 140.7,7.8 C142.5,7.8 144,6.36 144,4.5 C144,2.64 142.5,1.26 140.7,1.26 C138.9,1.2 137.4,2.64 137.4,4.5 M139.44,6.42 L140.04,6.42 L140.04,4.8 L140.7,4.8 L141.72,6.48 L142.38,6.48 L141.3,4.8 C141.9,4.74 142.32,4.44 142.32,3.78 C142.32,3.06 141.9,2.7 141,2.7 L139.5,2.7 L139.5,6.42 M140.04,3.06 L140.82,3.06 C141.24,3.06 141.66,3.12 141.66,3.66 C141.66,4.26 141.18,4.32 140.64,4.32 L140.04,4.32 L140.04,3.06 Z"
              id="Shape"
              fill="#333366"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default USPSLogo;
