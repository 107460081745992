import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Container from "@material-ui/core/Container";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import USPSLogo from "../../shared/usps-icons/USPSLogo";
import { unescape } from "lodash";

function Navbar({
  children,
  showReturnsHubText,
  showLogoutLink,
  logoutText,
  enableSidebarMenu,
  onOpenMenu,
  openMenu,
  retailerName,
  showConsumerLinks,
}) {
  const classes = useStyles();
  return (
    <Toolbar disableGutters className={classes.toolbar}>
      <Container maxWidth={false} className={classes.flexContainer}>
        {enableSidebarMenu && (
          <IconButton
            edge="start"
            className={[classes.menuButton, "navigation-hamburger"].join(" ")}
            onClick={onOpenMenu}
            aria-label="menu"
          >
            {openMenu ? (
              <CloseIcon color="inherit" />
            ) : (
              <MenuIcon color="inherit" />
            )}
          </IconButton>
        )}
        <div className={classes.logo}>
          <Link href="/">
            <USPSLogo />
          </Link>
        </div>
        {showReturnsHubText && (
          <Typography
            color="primary"
            variant="h4"
            style={{ fontWeight: "bold" }}
          >
            RETURNS HUB
          </Typography>
        )}
        <div className={classes.grow}></div>
        {showConsumerLinks && (
          <Fragment>
            <Hidden smUp>
              <IconButton
                edge="end"
                className={classes.menuButton}
                onClick={onOpenMenu}
                aria-label="menu"
              >
                <MenuIcon color="inherit" />
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <div className={classes.linksContainer}>
                <MenuItem
                  className={classes.link}
                  component={Link}
                  href="/users/sign_up"
                >
                  USPS Sales Sign Up
                </MenuItem>
                <MenuItem
                  className={classes.link}
                  component={Link}
                  href="https://faq.usps.com/s/"
                  target="_blank"
                >
                  FAQs
                </MenuItem>
              </div>
            </Hidden>
          </Fragment>
        )}
        {retailerName && (
          <Typography className={classes.retailerName} variant="body1">
            {unescape(retailerName)}
          </Typography>
        )}
        {logoutText && (
          <Typography className={classes.logoutText}>{logoutText}</Typography>
        )}
        {showLogoutLink && (
          <IconButton component="a" edge="end" href="/users/sign_out">
            <ExitToAppIcon className={classes.logoutIcon} />
          </IconButton>
        )}
      </Container>
    </Toolbar>
  );
}
export default Navbar;

export const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor: "white",
      flexDirection: "column",
    },
    logo: {
      marginRight: theme.spacing(2),
      display: "flex",
    },
    flexContainer: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
    grow: {
      flexGrow: 1,
    },
    logoutIcon: {
      color: theme.palette.primary.main,
    },
    retailerName: {
      marginRight: theme.spacing(2),
      color: "#000",
    },
    link: {
      color: "black",
      textDecoration: "none",
    },
    linksContainer: {
      display: "flex",
      flexDirection: "row",
    },
    logoutText: {
      marginRight: 10,
      color: "#424242",
    },
  }),
  { name: "Navbar" },
);
