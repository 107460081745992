import React from "react";
import { render } from "react-dom";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";

import FormWrapper from "../usps-devise-app/FormWrapper";
import useTheme from "../usps-consumer-app/theme/mui";

const useStyles = makeStyles(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    rememberMeContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    errorContainer: {
      color: theme.palette.common.white,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    input: {
      backgroundColor: theme.palette.common.white,
    },
  }),
  { name: "Login" },
);

const ACTION_FAILED =
  window.action_state &&
  window.action_state.findIndex((item) => item.type === "error") !== -1 &&
  window.action_state[
    window.action_state.findIndex((item) => item.type === "error")
  ].message;

const ACTION_INFO =
  window.action_state &&
  window.action_state.findIndex((item) => item.type === "info") > -1 &&
  window.action_state[
    window.action_state.findIndex((item) => item.type === "info")
  ].message;

const Content = () => {
  const classes = useStyles();
  const authenticityToken = window.nvo_config.form_authenticity_token;

  return (
    <FormWrapper>
      <Typography className={classes.title} variant="h1">
        USPS Returns Hub
      </Typography>

      <form
        id="new_user"
        action="/users/sign_in"
        acceptCharset="UTF-8"
        method="post"
      >
        <Grid spacing={2} container>
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          />

          <Grid item xs={12}>
            <TextField
              required
              id="user_email"
              autoComplete="email"
              autoFocus
              type="email"
              name="user[email]"
              label="Email"
              margin="none"
              variant="outlined"
              fullWidth
              className={classes.input}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="user_password"
              autoComplete="current-password"
              type="password"
              name="user[password]"
              label="Password"
              margin="none"
              variant="outlined"
              fullWidth
              className={classes.input}
            />
          </Grid>

          {ACTION_FAILED && (
            <Grid item xs={12}>
              <div className={classes.errorContainer}>
                <Typography align="left" color="inherit" variant="body1">
                  {ACTION_FAILED}
                </Typography>
              </div>
            </Grid>
          )}

          {ACTION_INFO && (
            <Grid item xs={12}>
              <div className={classes.errorContainer}>
                <Typography align="left" color="inherit" variant="body1">
                  {ACTION_INFO}
                </Typography>
              </div>
            </Grid>
          )}

          <Grid item xs={12} className={classes.rememberMeContainer}>
            <Typography variant="body1">Remember Me</Typography>
            <Checkbox
              color="primary"
              label="Remember Me"
              name="user[remember_me]"
              id="user_remember_me"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              color="primary"
              size="large"
              variant="outlined"
              type="submit"
              fullWidth
            >
              <strong>Sign In</strong>
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Link
              color="primary"
              href="/users/password/new"
              underline="always"
              variant="h4"
            >
              Forgot your password?
            </Link>
          </Grid>
        </Grid>
      </form>
    </FormWrapper>
  );
};

const App = () => {
  const theme = useTheme();
  // console.log(window.nvo_config);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Content />
    </ThemeProvider>
  );
};

render(<App />, document.querySelector("#root"));
