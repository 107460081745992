import "../../usps-retailer-app/theme/css/fonts.css";

import { useMemo } from "react";
import createTheme, {
  createBaseTheme,
} from "../../consumer-app/theme/create-theme";
import { buildSansFontFamily } from "../../shared/modules/fonts";

const branding = {
  id: 1,
  shop_id: 1,
  primary_font: "Helvetica",
  secondary_font: "Helvetica",
  base_body_text_size: 16,
  is_link_underlined: true,
  is_link_all_caps: false,
  border_radius: 0,
  brand_primary: "333366",
  brand_secondary: "333366",
  body_text: "000",
  body_background: "ffffff",
  brand_cta: "333366",
  background_image_url: null,
};

const fontFamilyBold = buildSansFontFamily("Helvetica Bold", "Helvetica");

function useTheme() {
  return useMemo(
    () =>
      createTheme(createBaseTheme(branding), {
        overrides: {
          MuiOutlinedInput: {
            root: {
              borderRadius: 4,
            },
          },
          MuiAutocomplete: {
            inputRoot: {
              '&[class*="MuiOutlinedInput-root"]': {
                padding: 0,
                "& $input": {
                  padding: "22px 8px 2px",
                },
              },
            },
          },
          MuiButton: {
            root: {
              textTransform: "initial",
              borderRadius: 4,
            },
            outlined: {
              backgroundColor: "#fff",
            },
          },
          MuiSelect: {
            select: {
              padding: "12px 12px",
            },
          },
        },
        typography: {
          h1: {
            fontFamily: fontFamilyBold,
          },
          h2: {
            fontFamily: fontFamilyBold,
          },
          h3: {
            fontFamily: fontFamilyBold,
          },
          h4: {
            fontFamily: fontFamilyBold,
          },
          h5: {
            fontFamily: fontFamilyBold,
          },
          h6: {
            fontFamily: fontFamilyBold,
          },
          subtitle2: {
            fontFamily: fontFamilyBold,
          },
          subtitle1: {
            fontFamily: fontFamilyBold,
          },
        },
      }),
    [],
  );
}

export default useTheme;
