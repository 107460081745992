import React, { useState } from "react";

import AppBar from "@material-ui/core/AppBar";

import Navbar from "../../usps-retailer-app/components/Navbar";
import AppDrawer from "./AppDrawer";

function Navigation() {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => setOpenDrawer((state) => !state);

  return (
    <React.Fragment>
      <AppBar position="fixed" style={{ borderBottom: "1px solid #d3d3d3" }}>
        <Navbar fixed showConsumerLinks onOpenMenu={toggleDrawer} />
      </AppBar>
      <AppDrawer open={openDrawer} onClose={toggleDrawer} />
    </React.Fragment>
  );
}
export default Navigation;
