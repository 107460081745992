import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import Navigation from "../usps-consumer-app/components/Navigation";
import clsx from "clsx";

const backgroundImageUrl =
  "https://storage.googleapis.com/shopify-zero-narvar/rtr3b1ao.jpg";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: `url("${backgroundImageUrl}") no-repeat center center fixed`,
      backgroundSize: "cover",
      minHeight: "100vh",
      display: "flex",
      alignItems: "center",
    },
    rootPaddingTop: theme.mixins.toolbarHeightForProp("paddingTop"),
    rootPaddingBottom: theme.mixins.toolbarHeightForProp("paddingBottom"),
    container: {
      marginTop: theme.spacing(15.5),
      marginBottom: theme.spacing(15.5),
    },
    content: {
      backgroundColor: "rgba(255, 255, 255, 0.95)",
      padding: theme.spacing(4),
    },
  }),
  { name: "FormWrapper" },
);

const FormWrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Navigation />
      <div
        className={clsx(
          classes.root,
          classes.rootPaddingBottom,
          classes.rootPaddingTop,
        )}
      >
        <Container className={classes.container}>
          <Grid container align="center" justifyContent="center">
            <Grid xs={12} sm={8} md={6} lg={4} item>
              <div className={classes.content}>{children}</div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormWrapper;
