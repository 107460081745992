import { createTheme as createMuiTheme } from "@material-ui/core";
import merge from "lodash/merge";
import { buildSansFontFamily } from "../../shared/modules/fonts";

const muiTheme = createMuiTheme();

export const palette = {
  narvarBlue: "#0096d4",
  navy: "#0e1260",
  green: "#43a97a",
  red: "#da4a33",
  frost: "#e5f5fb",
  cloud: "#dadcea",
  pistachio: "#e1efe7",
  coconut: "#fde8de",
};

export const common = {
  black: "#000000",
  charcoal: "#252525",
  ash: "#666666",
  steal: "#cccccc",
  heather: "#f2f2f2",
  fog: "#f9f9f9",
  white: "#ffffff",
};

export const misc = {
  alert: "#f90000",
  warning: "#ffcb12",
  success: "#00d164",
  info: "#0096d4",
};

export const createBaseTheme = (branding) => {
  const fontSize = branding.base_body_text_size;

  // @TODO fix this
  const brandPrimary = "#" + branding.brand_primary;
  const brandSecondary = "#" + branding.brand_secondary;
  const bodyText = "#" + branding.body_text;
  const bodyBackground = "#" + branding.body_background;
  // const brandCta = "#" + branding.brand_cta;

  return createMuiTheme({
    palette: {
      background: {
        default: bodyBackground,
      },
      primary: {
        main: brandPrimary,
      },
      secondary: {
        main: brandSecondary,
      },
      text: {
        primary: bodyText,
      },
      error: {
        main: "#DC4932",
      },
    },
    props: {
      MuiAppBar: {
        elevation: 0,
      },
      MuiButton: {
        disableRipple: true,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButtonGroup: {
        disableRipple: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
      MuiLink: {
        underline: branding.is_link_underlined ? "always" : "none",
      },
      MuiPaper: {
        elevation: 0,
      },
      MuiSwitch: {
        disableRipple: true,
      },
      MuiTypography: {
        color: "textPrimary",
      },
      MuiOutlinedInput: {
        notched: false,
      },
    },
    shape: {
      borderRadius: branding.border_radius,
    },
    typography: {
      fontSize,
      fontFamily: buildSansFontFamily(branding.primary_font),
      isLinkAllCaps: branding.is_link_all_caps,
      secondaryFontFamily: buildSansFontFamily(branding.secondary_font),
      body1: {
        fontSize: fontSize, // 14
        lineHeight: 1.715, // 24
      },
      body2: {
        fontSize: fontSize * 1.14, // 16
        lineHeight: 1.5, // 24
      },
      caption: {
        fontSize: fontSize * 0.857, // 12
        lineHeight: 1.33, // 16
      },
      h1: {
        fontSize: fontSize * 2.285, // 32
        fontWeight: 600,
        lineHeight: 1.25, // 40
      },
      h2: {
        fontSize: fontSize * 1.715, // 24
        fontWeight: 600,
        lineHeight: 1.33, // 32
      },
      h3: {
        fontSize: fontSize * 1.285, // 18
        fontWeight: 600,
        lineHeight: 1.33, // 24
      },
      h4: {
        fontSize: fontSize * 1.14, // 16
        fontWeight: 600,
        lineHeight: 1.5, // 24
      },
      h5: {
        fontSize: fontSize * 1,
        fontWeight: 600,
        lineHeight: 1.5, // 24
      },
      h6: {
        fontSize: fontSize * 0.75,
        fontWeight: 600,
        lineHeight: 1.5, // 24
      },
    },
  });
};

const buildToolbarHeightForProp = (cssProp = "minHeight", negative) => ({
  [cssProp]: negative ? -64 : 64,
  [`${muiTheme.breakpoints.up("xs")} and (orientation: landscape)`]: {
    [cssProp]: negative ? -48 : 48,
  },
  [muiTheme.breakpoints.up("sm")]: {
    [cssProp]: negative ? -80 : 80,
  },
});

const createTheme = (baseTheme, overrides) =>
  createMuiTheme(
    merge(
      {},
      baseTheme,
      {
        mixins: {
          toolbar: buildToolbarHeightForProp("minHeight"),
          toolbarHeightForProp: buildToolbarHeightForProp,
        },
        overrides: {
          MuiButton: {
            root: {
              padding: `${muiTheme.spacing(1)}px ${muiTheme.spacing(2)}px`,
            },
            contained: {
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                "@media (hover: none)": {
                  boxShadow: "none",
                },
              },
              "&$focusVisible": {
                boxShadow: "none",
              },
              "&:active": {
                boxShadow: "none",
              },
              "&$disabled": {
                boxShadow: "none",
              },
            },
            containedSecondary: {
              color: baseTheme.palette.common.white,
            },
            containedPrimary: {
              color: baseTheme.palette.common.white,
            },
            textPrimary: {
              color: `${baseTheme.palette.primary.main}`,
              textTransform: "none",
              fontSize: "0.875rem",
              whiteSpace: "nowrap",
              "&:hover": {
                backgroundColor: "transparent",
                textDecoration: "underline",
              },
            },
            outlinedPrimary: {
              border: `1px solid ${baseTheme.palette.primary.main}`,
              transition: "none",
              "&:hover": {
                border: `1px solid ${baseTheme.palette.primary.main}`,
                backgroundColor: "transparent",
              },
            },
          },
          MuiInputBase: {
            input: {
              height: muiTheme.spacing(3),
            },
          },
          MuiInputLabel: {
            outlined: {
              lineHeight: `${muiTheme.spacing(2)}px`,
              transform: `translate(${muiTheme.spacing(
                1,
              )}px, ${muiTheme.spacing(2)}px) scale(1)`,
              "&$shrink": {
                transform: `translate(${muiTheme.spacing(
                  1,
                )}px, ${muiTheme.spacing(1)}px) scale(0.8)`,
              },
            },
          },
          MuiLink: {
            root: {
              fontWeight: 500,
              cursor: "pointer",
              textTransform: baseTheme.typography.isLinkAllCaps
                ? "uppercase"
                : "none",
            },
          },
          MuiMenuItem: {
            root: {
              fontSize: baseTheme.typography.fontSize,
              lineHeight: 1.715,
              fontWeight: "bold",
            },
          },
          MuiOutlinedInput: {
            input: {
              fontSize: baseTheme.typography.fontSize,
              height: muiTheme.spacing(3),
              padding: "22px 8px 2px",
            },
          },
          MuiSnackbarContent: {
            root: {
              flexWrap: "nowrap",
              alignItems: "flex-start",
            },
          },
        },
      },
      overrides,
    ),
  );

export default createTheme;
